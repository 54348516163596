// ArtPage.js
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './artPage.scss';
import ArtsService from '../../services/arts.service';
import ImagesSwiper from '../swiper/swiper.js';

const getWindowDimensions = () => ({
    width: window.innerWidth,
    height: window.innerHeight
});

export default function ArtPage() {
    const { id } = useParams();
    const navigate = useNavigate(); // <-- Add useNavigate
    const [art, setArt] = useState({});
    const [currentTranslate, setCurrentTranslate] = useState({});
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { t } = useTranslation();

    const getArt = useCallback(async (artId) => {
        const artsService = new ArtsService();
        const fetchedArt = await artsService.getArt(artId);
        setArt(fetchedArt);

        const langId = artsService.getLangId();
        const translation = fetchedArt.translate?.find(translate => translate.type === langId) || fetchedArt.translate?.[0];
        console.log('langId -->', langId, translation, fetchedArt);
        setCurrentTranslate(translation || {});
    }, []);

    useEffect(() => {
        if (id) {
            getArt(id);
        }

        const handleResize = () => setWindowDimensions(getWindowDimensions());
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
    }, [id, getArt]);

    const getStatus = (statusId) => (statusId === 2 ? t('in_stock') : t('sold'));

    const getPrice = (price, statusId) => (statusId === 2 ? `${t('price')}: ${price}$` : '');

    // Function to navigate to ArtsListPage with series filter
    const handleSeriesClick = () => {
        navigate(`/arts?series=${art.series || 'all'}`);
    };

    return (
        <div className="artPage">
            <div className="descriptionWrapper">
                <div className="shortDescription">
                    <div className="artName">{currentTranslate.name}</div>
                    <div>{t('size')}: {art.size}</div>
                    <div>{t('year')}: {art.year}</div>
                    <div>{t('materials')}: {currentTranslate.materials}</div>
                    <div>{t('series')}: <span onClick={handleSeriesClick} className="seriesButton">
                            {art.series ? t(art.series) : t('without')}
                        </span>
                    </div>
                    <div>{t('frame')}: {currentTranslate.frame ? currentTranslate.frame : t('withoutFrame')}</div>
                    <div>{getPrice(art.price, art.saleStatusId)}</div>
                    <div>{getStatus(art.saleStatusId)}</div>
                    <div>{art.saleStatusId ? currentTranslate.location : ''}</div>
                </div>
                <div className="textDescription">
                    {currentTranslate.description}
                </div>
            </div>

            <div className="photos">
                {art.photos && <ImagesSwiper photos={art.photos} pageWidth={windowDimensions.width} />}
            </div>

            <div className="emptyBlock" />
        </div>
    );
}