import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            paintings: "paintings",
            about: "about",
            contacts: "contacts",
            size: "Size",
            year: "Year",
            materials: "Materials",
            in_stock: "In stock",
            sold: "Sold",
            all: "All",
            price: "Price",
            contact_first_line: "Kyiv, Ukraine",
            contact_second_line: "Email: khomslava@gmail.com",
            contact_third_line: "Tel: +38 067 506-39-18",
            frame: "Frame",
            withoutFrame: "Without",
            series: "Series",
            touch: "Touch",
            tenderness: "Tenderness",
            feeling: "Feeling",
            space: "Space",
            outOfBounds: "Out of bounds",
            light: "Light",
            without: "Without"
        }
    },
    ua: {
        translation: {
            paintings: "картини",
            about: "о нас",
            contacts: "контакти",
            size: "Розмір",
            year: "Рік",
            materials: "Матеріали",
            in_stock: "В наявності",
            sold: "Продано",
            all: "Всі",
            price: "Ціна",
            contact_first_line: "Київ, Україна",
            contact_second_line: "Email: khomslava@gmail.com",
            contact_third_line: "Тел: +38 067 506-39-18",
            series: "Серії",
            frame: "Рама",
            withoutFrame: "Без",
            touch: "Дотик",
            tenderness: "Ніжність",
            feeling: "Почуття",
            space: "Простір",
            outOfBounds: "Поза межами",
            light: "Легкість",
            without: "Усіляке"
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        debug: true,
        lng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;