// ArtsListPage.js
import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { Gallery } from '../gallery';
import ArtsService from '../../services/arts.service';
import { Translation } from 'react-i18next';
import './artsListPage.scss';

const artsService = new ArtsService();

class ArtsListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arts: [],
            filteredArts: [],
            seriesFilter: 'all',
            availabilityFilter: 'all',
            translateId: artsService.getLangId(),
        };
    }

    async componentDidMount() {
        const arts = await artsService.getArts();
        this.setState({ arts, filteredArts: arts });
        
        // Apply series filter from URL if it exists
        const urlParams = new URLSearchParams(this.props.location.search);
        const series = urlParams.get('series');
        if (series) {
            this.filterArts(series, 'all'); // Initial filter with series from URL
        }
    }

    filterArts = (newSeriesFilter, newAvailabilityFilter) => {
        const { arts, seriesFilter, availabilityFilter } = this.state;
        const activeSeriesFilter = newSeriesFilter || seriesFilter;
        const activeAvailabilityFilter = newSeriesFilter 
            ? 'all' 
            : newAvailabilityFilter || availabilityFilter;

        let filteredArts = arts.filter(art => {
            return activeSeriesFilter === 'all' || art.series === activeSeriesFilter || activeSeriesFilter === 'without' && (art.series === "" || art.series === "without");
        });

        if (activeAvailabilityFilter === 'inStock') {
            filteredArts = filteredArts.filter(art => [2, 3].includes(art.saleStatusId));
        } else if (activeAvailabilityFilter === 'sold') {
            filteredArts = filteredArts.filter(art => art.saleStatusId === 1);
        }

        this.setState({
            seriesFilter: activeSeriesFilter,
            availabilityFilter: activeAvailabilityFilter,
            filteredArts
        });
    }

    getActiveFilterName = (filterType, filterName) => (
        this.state[filterType] === filterName ? 'is-active' : ''
    );

    renderFilterButtons = (filters, filterType, t) => (
        filters.map(filter => (
            <div
                key={filter.key}
                className={`button ${this.getActiveFilterName(filterType, filter.key)}`}
                onClick={() => this.filterArts(
                    filterType === 'seriesFilter' ? filter.key : null,
                    filterType === 'availabilityFilter' ? filter.key : null
                )}
            >
                {t(filter.label)}
            </div>
        ))
    );

    render() {
        const { filteredArts, translateId } = this.state;
        const orderedArts = filteredArts.sort((a, b) => a.order - b.order);

        const seriesFilters = [
            { key: 'all', label: 'all' },
            { key: 'feeling', label: 'feeling' },
            { key: 'tenderness', label: 'tenderness' },
            { key: 'touch', label: 'touch' },
            { key: 'outOfBounds', label: 'outOfBounds' },
            { key: 'light', label: 'light' },
            { key: 'space', label: 'space' },
            { key: 'without', label: 'without' },
        ];

        const availabilityFilters = [
            { key: 'all', label: 'all' },
            { key: 'inStock', label: 'in_stock' },
        ];

        return (
            <Translation>
                {t => (
                    <div className="arts">
                        <div className="filter series">
                            <div className="title">{t('series')}:</div>
                            {this.renderFilterButtons(seriesFilters, 'seriesFilter', t)}
                        </div>
                        <div className="filter available">
                            {this.renderFilterButtons(availabilityFilters, 'availabilityFilter', t)}
                        </div>
                        <Gallery images={orderedArts} translateId={translateId} />
                        <ScrollToTop showUnder={160}>
                            <img className="scroll-up-img" src="/triangle.jpg" alt="Up" />
                        </ScrollToTop>
                    </div>
                )}
            </Translation>
        );
    }
}

// Wrap ArtsListPage to pass location as props
export default function ArtsListPageWrapper() {
    const location = useLocation();
    return <ArtsListPage location={location} />;
}